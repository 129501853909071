/* css/box.css */

/* box layout */
.hbox{display:flex; justify-content:flex-start; align-items:stretch; flex-direction:row;}
.vbox{display:flex; justify-content:flex-start; align-items:stretch; flex-direction:column;}
.client{display:flex; flex:1;position:relative; height:100%; width:100%;}

[valign="top"], .v-align--top {align-items:flex-start;}
[valign="bottom"], .v-align--bottom {align-items:flex-end;}
[valign="center"], .v-align--center {align-items:center;}

[halign="left"], .h-align--left {justify-content:flex-start;}
[halign="right"], .h-align--right {justify-content:flex-end;}
[halign="center"], .h-align--center {justify-content:center;}

.vbox[layout="space-around"], .hbox[layout="space-around"] {justify-content:space-around;}
.vbox[layout="space-between"], .hbox[layout="space-between"]{justify-content:space-between;}
.vbox[layout="center center"], .hbox[layout="center center"]{justify-content:center; align-items:center;}

.spacer{flex-grow: 1;}

[box-test]{ border: solid 1px red; padding: 2px; }

.container{
    display: block;
    overflow-y: auto;
    padding-bottom:40px;
}
